import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
} from "utils/helper";

export interface C2cShippment {
  all_C2cShippment: any;
  all_C2cShippment_data: any;
  all_C2cShippment_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: C2cShippment = {
  all_C2cShippment: [],
  all_C2cShippment_data: [],
  all_C2cShippment_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const allC2CShippment = createAsyncThunk(
  "/c2c-Shippment",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "c2c-shippment"
      );
      // console.log(response,"response")
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "C2C_Shipment",
  initialState,
  reducers: {
    setSelectedC2CShippment: (state: C2cShippment, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllC2CShippment: (state: C2cShippment) => {
      state.all_C2cShippment = [];
      state.all_C2cShippment_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allC2CShippment.pending, (state: C2cShippment, { payload }) => {
        state.activity = true;
      })
      .addCase(
        allC2CShippment.fulfilled,
        (state: C2cShippment, { payload }) => {
          let shippment = [] as any;
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            shippment.push([
              item?.id || "- - -",
              // { name: item?.id, hidden: true },
              item?.created_at
                ? //  moment(item?.created_at).format("DD/MM/YYYY")
                  moment(
                    `${item?.created_at}`.endsWith("Z")
                      ? item?.created_at
                      : item?.created_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",
              item?.sender_name || "- - -",
              {
                name: getStatusLabel(item?.ship_status as string) ?? "-",
                is_bool: true,
                // c2c_shippment:true,
                // color:
                //   item?.ship_status === "pending"
                //     ? getStatusLabelColor(item?.ship_status as string ,true)
                //     : "black",
                style: {
                  color:
                    getStatusLabelColor(item?.ship_status as string, true) ??
                    "black",
                },
              },
              item?._type || "- - -",

              `${
                item?.offered_price
                  ? numberFormatWithoutCurrency(item?.offered_price)
                  : "0"
              } €`,
              item?.agree_price
                ? `${numberFormatWithoutCurrency(item?.agree_price)} €`
                : "- - -",

              item?.ship_delivery_type ?? "- - -",

              item?.payment_method ?? "- - -",

              item?.payment_status ?? "- - -",
              // item?.size_category ?? "- - - ",
              item?.size_category === "Regular"
                ? "Small"
                : item?.size_category ?? "- - - ",

              item?.transporter ?? "- - - ",
              item?.deliver_or_cancelled_at
                ? // moment(item?.deliver_or_cancelled_at).format("DD/MM/YYYY")
                  moment(
                    `${item?.deliver_or_cancelled_at}`.endsWith("Z")
                      ? item?.deliver_or_cancelled_at
                      : item?.deliver_or_cancelled_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",
            ]);
          }
          state.all_C2cShippment = shippment;
          state.all_C2cShippment_data = payload?.data;
          state.all_C2cShippment_paginate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };

          state.activity = false;
        }
      );
  },
});

export const { setSelectedC2CShippment, clearAllC2CShippment } =
  orderSlice.actions;

export default orderSlice.reducer;

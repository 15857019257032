/*
  Created By Muhammad Hassaan
  Date 12 April 2023
*/
import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
export default function LoadingComponent(props: any) {
  const { activity } = props;
  return (
    <Dialog
      open={activity}
      maxWidth="sm"
      hideBackdrop
      PaperProps={{
        style: {
          background: "transparent",
          boxShadow: "none",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent
        style={{
          overflow: "hidden",
        }}
      >
        <section>
          <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
              <div className="animation-preloader">
                {/* <div className="icon">&nbsp;</div> */}
                <div className="txt-loading mt-2">
                  <span data-text-preloader="F" className="letters-loading">
                    F
                  </span>
                  <span data-text-preloader="R" className="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="A" className="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="N" className="letters-loading">
                    N
                  </span>
                  <span data-text-preloader="K" className="letters-loading">
                    K
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
}

LoadingComponent.defaultProps = {
  activity: false,
};

LoadingComponent.propTypes = {
  activity: PropTypes.bool,
};

import { configureStore } from "@reduxjs/toolkit";
import BreadcrumbSlice from "./breadcrumbReducer";
import drawerReducer from "./drawerReducer";
import orderReducers from "./orderReducers";
import customerReducer from "./customerReducer";
import transporterReducers from "./transporterReducers";
import vendorReducers from "./vendorReducers";
import paymentReducers from "./paymentReducers";
import notificationSlicer from "./notificationReducer";
import reportingSlicer from "./reportingReducer";
import commissionReducer from "./commissionReducer";
import shippingRateSlicer from "./shippingRatesReducer";
import adminUserReducer from "./adminUserReducer";
import bannerReducer from "./bannerReducer";
import BlogsReducer from "./BlogsReducer";
import comments from './commentsReducer/indext'
import annoucementReducer from "./annoucementReducer";
import itemListReducer from "./itemListReducer";
import productReducer from "./productReducer";
import catalogReducer from "./catalogReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    breadcrumb: BreadcrumbSlice,
    orders: orderReducers,
    customers: customerReducer,
    transporterSlice: transporterReducers,
    vendors: vendorReducers,
    payments: paymentReducers,
    notifications: notificationSlicer,
    reporting: reportingSlicer,
    commissions: commissionReducer,
    shipping_ratess: shippingRateSlicer,
    admin_users: adminUserReducer,
    banners:bannerReducer,
    blogs:BlogsReducer,
// comments:commentReducer,
    comments:comments,
    c2cShippment:annoucementReducer,
    itemList:itemListReducer,
    shopProduct:productReducer,
    catalog:catalogReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Login = Loadable(lazy(() => import("views/Auth/Login")));
export const BulkImage = Loadable(lazy(() => import("views/UploadImages")));
export const Dashboard = Loadable(lazy(() => import("views/Dashboard")));
export const AllOrders = Loadable(lazy(() => import("views/Orders/AllOrders")));
export const C2CAllOrders = Loadable(
  lazy(() => import("views/Orders/MiniShopOrder/AllOrders"))
);

export const C2COrdersById = Loadable(
  lazy(() => import("views/Orders/MiniShopOrder/OrderDetail/OrderDetail"))
);

export const AllSpaceOrders = Loadable(
  lazy(() => import("views/Orders/AllSpaceOrders"))
);
export const Customers3P = Loadable(
  lazy(() => import("views/Customers/Customers3P"))
);

export const SpaceCustomers = Loadable(
  lazy(() => import("views/Customers/SpaceCustomers"))
);

export const AppCustomers = Loadable(
  lazy(() => import("views/Customers/AppCustomer"))
);
export const BusinessCustomer = Loadable(
  lazy(() => import("views/Customers/BusinessCustomer"))
);
export const BusinessCustomerById = Loadable(
  lazy(() => import("views/Customers/BusinessCustomer/BusCustomerById"))
);

export const FleetManagers = Loadable(
  lazy(() => import("views/Transporters/Fleetmanagers"))
);
export const FleetByID = Loadable(
  lazy(() => import("views/Transporters/Fleetmanagers/FleetByID"))
);
export const AllSubRiders = Loadable(
  lazy(() => import("views/Transporters/SubRiders"))
);
export const AllRiders = Loadable(
  lazy(() => import("views/Transporters/Riders"))
);
export const RiderByID = Loadable(
  lazy(() => import("views/Transporters/Riders/RiderByID"))
);

export const AllAirRider = Loadable(
  lazy(() => import("views/Transporters/AirRiders"))
);
export const AirRiderByID = Loadable(
  lazy(() => import("views/Transporters/AirRiders/AirRiderByID"))
);
export const AllEmerchants = Loadable(
  lazy(() => import("views/Vendors/Emerchants"))
);
export const EMerchantByID = Loadable(
  lazy(() => import("views/Vendors/Emerchants/EmerchabtByID"))
);
export const AllSpaces = Loadable(lazy(() => import("views/Vendors/Spaces")));
export const SpaceByID = Loadable(
  lazy(() => import("views/Vendors/Spaces/SpaceByID"))
);

export const AllD2C = Loadable(lazy(() => import("views/Vendors/D2C")));
export const D2CBusinessByID = Loadable(
  lazy(() => import("views/Vendors/D2C/D2CByID"))
);

export const AllInvoices = Loadable(
  lazy(() => import("views/Payments/invoices/AllInvoices"))
);

export const AllPayments = Loadable(
  lazy(() => import("views/Payments/payments/AllPayments"))
);

export const Statement = Loadable(
  lazy(() => import("views/Payments/Statement"))
);
export const SendNotification = Loadable(
  lazy(() => import("views/Notifications"))
);
export const NotificationReport = Loadable(
  lazy(() => import("views/Notifications/Reports"))
);

export const Reporting = Loadable(lazy(() => import("views/Reporting")));

export const Settings = Loadable(lazy(() => import("views/Settings")));
export const Rates = Loadable(lazy(() => import("views/Payments/Rates")));
export const ShippingRates = Loadable(
  lazy(() => import("views/Payments/ShippingRates"))
);
export const ShippingRateTypes = Loadable(
  lazy(() => import("views/Payments/ShippingRates/RateTypes"))
);

export const Commission = Loadable(
  lazy(() => import("views/Payments/Commission"))
);

export const CommissionType = Loadable(
  lazy(() => import("views/Payments/Commission/CommissionTypes"))
);

export const NotFound = Loadable(lazy(() => import("views/NotFound")));

export const SupportAndChat = Loadable(lazy(() => import("views/Chats")));

export const ManageUsers = Loadable(
  lazy(() => import("views/Settings/ManageUsers"))
);

export const UserDetails = Loadable(
  lazy(() => import("views/Settings/ManageUsers/Details"))
);

export const ManageRoles = Loadable(
  lazy(() => import("views/Settings/ManageRoles"))
);

export const RoleDetails = Loadable(
  lazy(() => import("views/Settings/ManageRoles/Details"))
);
export const BannerManagement = Loadable(
  lazy(() => import("views/BannerManagement"))
);
export const BannerManagementById = Loadable(
  lazy(() => import("views/BannerManagement/BannerByid"))
);

export const AllMiniShop = Loadable(
  lazy(() => import("views/Vendors/MiniShop"))
);
export const MiniShopById = Loadable(
  lazy(() => import("views/Vendors/MiniShop/MiniShopByID"))
);
export const C2CPricing = Loadable(
  lazy(() => import("views/C2CPricing/AnnoucementPricing"))
);

export const TransportFee = Loadable(
  lazy(() => import("views/C2CPricing/TransportFee"))
);
export const PlatFormFee = Loadable(
  lazy(() => import("views/C2CPricing/PlatformFee"))
);
export const FrankCommissions = Loadable(
  lazy(() => import("views/C2CPricing/Commission"))
);
export const Blogs = Loadable(lazy(() => import("views/Blogs")));

export const BlogsById = Loadable(lazy(() => import("views/Blogs/BlogsById")));

export const C2CShippment = Loadable(
  lazy(() => import("views/Orders/Annoucement"))
);
export const C2CShippmentById = Loadable(
  lazy(() => import("views/Orders/Annoucement/OrderDetail/OrderDetail"))
);

export const ListItem = Loadable(lazy(() => import("views/ItemList")));
export const ItemByid = Loadable(lazy(() => import("views/ItemList/itemById")));
export const UserAcitvity = Loadable(lazy(() => import("views/UserActivity")));
/// new v2 routing
export const Annoucement = Loadable(
  lazy(() => import("views/OMS/Annoucement"))
);
export const AnnoucementById = Loadable(
  lazy(() => import("views/OMS/Annoucement/OrderDetail/OrderDetail"))
);
export const Orders = Loadable(
  lazy(() => import("views/OMS/MiniShopOrder/AllOrders"))
);
export const OrdersById = Loadable(
  lazy(() => import("views/OMS/MiniShopOrder/OrderDetail/OrderDetail"))
);

export const AVS = Loadable(lazy(() => import("views/OMS/AVS")));

export const Customers = Loadable(lazy(() => import("views/WMS/Customer")));
export const CustomerById = Loadable(
  lazy(() => import("views/WMS/Customer/BusCustomerById"))
);

export const Suppliers = Loadable(lazy(() => import("views/WMS/Suppliers")));
export const SuppliersById = Loadable(
  lazy(() => import("views/WMS/Suppliers/SuppliersByID"))
);

export const SuppliersOrder = Loadable(
  lazy(() => import("views/WMS/SupplierOrder"))
);
export const SuppliersOrderById = Loadable(
  lazy(() => import("views/WMS/SupplierOrder/OrderDetail/OrderDetail"))
);
export const Client = Loadable(lazy(() => import("views/CRM/Client")));
export const ClientById = Loadable(
  lazy(() => import("views/CRM/Client/ClientById/index"))
);

export const CrmProduct = Loadable(lazy(() => import("views/CRM/Products")));
export const CrmCustOrder = Loadable(lazy(() => import("views/CRM/CustOrder")));

export const TmsTransporter = Loadable(
  lazy(() => import("views/TMS/Transporter"))
);
export const TmsManageRoutes = Loadable(
  lazy(() => import("views/TMS/ManagaRoutes"))
);
export const TmsSenderOrder = Loadable(
  lazy(() => import("views/TMS/SenderOrder"))
);

export const RegularPricing = Loadable(
  lazy(() => import("views/C2CPricing/RegularPricing"))
);
export const V_categories = Loadable(
  lazy(() => import("views/Management/Category/index"))
);
export const V_categoriesById = Loadable(
  lazy(() => import("views/Management/Category/CategoryById"))
);
export const Attributes = Loadable(
  lazy(() => import("views/Management/Attributes"))
);
export const AttributesByName = Loadable(
  lazy(() => import("views/Management/Attributes/AttributeByName"))
);
export const Specification = Loadable(
  lazy(() => import("views/Management/Sprecification"))
);
export const SpecificationById = Loadable(
  lazy(() => import("views/Management/Sprecification/SpecById"))
);

export const AssignCatg = Loadable(
  lazy(() => import("views/Management/AssignCategory"))
);

// export const Categories = Loadable(
//   lazy(() => import("views/Management/Category/index"))
// );

// export const OMSAnnncouce

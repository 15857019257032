import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface BannerState {
  all_banner: any;
  all_banner_data: any;
  all_banner_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: BannerState = {
  all_banner: [],
  all_banner_data: [],
  all_banner_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const allBanners = createAsyncThunk(
  "/c2c-banners",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "banners");
      // console.log(response,"response")
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setSelectedBanner: (state: BannerState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllBanner: (state: BannerState) => {
      state.all_banner = [];
      state.all_banner_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allBanners.pending, (state: BannerState, { payload }) => {
        state.activity = true;
      })
      .addCase(allBanners.fulfilled, (state: BannerState, { payload }) => {
        let banners = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          banners.push([
            item?.id || "- - -",
            item?.added_by || "- - -",
            item?.banner_type || "- - -",
            // item?.is_active ? "Active" : "Inactive" || "- - -",
            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            moment(item?.start_date).format("DD MMMM YYYY") || "- - -",
            moment(item?.end_date).format("DD MMMM YYYY") || "- - -",
            {
              ui: (
                <div>
                  <img
                    src={item?.en.web_image}
                    alt="frank.jpeg"
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                  ></img>
                </div>
              ),
            },
            // item?.banner_image?.web || "- - -",
          ]);
        }
        state.all_banner = banners;
        state.all_banner_data = payload?.data;
        state.all_banner_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      });
  },
});

export const { setSelectedBanner, clearAllBanner } = orderSlice.actions;

export default orderSlice.reducer;

import axios from "axios";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import CryptoJS from "crypto-js";

export const BASE_URL = "https://p-server.frankme.com/api/v1/";
// export const BASE_URL = "http://192.168.18.59:4001/api/v1/";

const secretKey = "FR@NK-FR_2024!";
export const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

export const decrypt = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getApiRequestHeader = async () => {
  let authtoken = getDataFromLocalStorage("user");
  authtoken = authtoken?.token;
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authtoken ? `Bearer ${authtoken}` : "",
  };
};

let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 6000000,
}) as any;

export const updateHeaders = async () => {
  const header = await getApiRequestHeader();
  instance.defaults.headers = header as any;
};

export const request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders();
  }
  const promise = instance[method](url, data);
  let response;
  try {
    response = await promise;
  } catch (error: any) {
    return error.response?.data;
  }

  return response?.data;
  // try {
  //   response = await promise;
  // } catch (error: any) {
  //   let err = JSON.parse(JSON.parse(error.response?.data));
  //   return err;
  // }
  // let d_data = decrypt(response?.data);
  // let _data = JSON.parse(JSON.parse(d_data));
  // return _data;
};

export const get = (url: string, permission_name: string, config: any) => {
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  });
};

export const post = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "post",
    url,
    data,
    ...config,
  });
};

export const patch = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "patch",
    url,
    data,
    ...config,
  });
};

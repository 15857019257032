import moment from "moment";
import { defaultLabelColors } from "./constants";
import _ from "lodash";

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// export const numberFormatWithoutCurrency = (value: number, lang = 'de-DE') =>
//   Intl.NumberFormat(lang, {
//     maximumFractionDigits: 2,
//     minimumFractionDigits: 0,
//   }).format(value);
export const numberFormatWithoutCurrency = (value:number, lang:string = 'de-DE') => {
  // Ensure single-digit numbers are double digits
  const formattedValue = value < 10 && value >= 0 ? `0${value}` : value;

  return Intl.NumberFormat(lang, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(formattedValue));
}

export const numberFormatWithoutCurrencyFixed = (
  value: number,
  lang = 'de-DE',
) => Intl.NumberFormat(lang).format(value);

const numToWordObj = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  30: "Thirty",
  40: "Forty",
  50: "Fifty",
  60: "Sixty",
  70: "Seventy",
  80: "Eighty",
  90: "Ninety",
} as any;

const placement = {
  100: " Hundred",
  1000: " Thousand",
  1000000: " Million",
  1000000000000: " Trillion",
};

export const convertNumToWord = (num: any): any => {
  const limiter = (val: any) => (num < val) as any;
  const limiterIndex = Object.keys(placement).findIndex(limiter) as any;
  const limiterKey = Object.keys(placement)[limiterIndex] as any;
  const limiterVal = Object.values(placement)[limiterIndex - 1] as any;
  const limiterMod = Object.keys(placement)[limiterIndex - 1] as any;

  if (numToWordObj[num]) {
    return numToWordObj[num];
  }

  if (num < 100) {
    const whole = Math.floor(num / 10) * 10;
    const part = num % 10;
    return numToWordObj[whole] + " " + numToWordObj[part];
  }

  if (num < limiterKey) {
    const whole = Math.floor(num / limiterMod);
    const part = num % limiterMod;
    if (part === 0) {
      return convertNumToWord(whole) + limiterVal;
    }
    return (
      convertNumToWord(whole) + limiterVal + " and " + convertNumToWord(part)
    );
  }
};

export const getStatusLabel = (status: string, is_space?: true): string => {
  switch (status) {
    case "waiting":
      return "Waiting";
    case "pending":
      return is_space ? "Booked" : "Pending";

    case "accepted":
      return "Accepted";

    case "picked":
      return "Picked";

    case "delivered":
      return "Delivered";
    case "deliver":
      return "Delivered";
    case "ready":
      return "Ready";
    case "placed":
      return "Placed";
    case "cancelled_transporter":
      return "Cancelled by Transpoter";
    case "cancelled_back_office_admin":
      return "Cancelled by BO-Admin";
    case "cancelled_store_admin":
      return "Cancelled by Store";
    case "cancelled_customer":
      return "Cancelled by Customer";
    case "dispatched":
      return "Dispatched";
    case "in-progress":
      return "In progress";

    //frank shop order status
    case "Placed":
      return "Placed";
    case "Accepted":
      return "Accepted";
    case "Ready":
      return "Ready";
    case "Picked":
      return "Picked";
    case "Deliver":
      return "Delivered";
    case "Cancelled":
      return "Cancelled";

    default:
      return status;
  }
};

export const getStatusLabelColor = (
  status: string,
  is_space?: boolean,
  c2c_shippment?: boolean
): string => {
  switch (status) {
    case "waiting":
      return "Waiting";
    case "pending":
      return is_space
        ? defaultLabelColors.color_odr_plcd
        : defaultLabelColors.color_odr_plcd;
    case "accepted":
      return defaultLabelColors.color_odr_acp;
    case "picked":
      return defaultLabelColors.color_odr_pick;

    case "delivered":
      return defaultLabelColors.green;
    case "deliver":
      return defaultLabelColors.color_odr_delv;
    case "placed":
      return defaultLabelColors.color_odr_plcd;

    case "ready":
      return defaultLabelColors.light_gray;

    case "cancelled_transporter":
      return defaultLabelColors.red;
    case "cancelled_back_office_admin":
      return defaultLabelColors.red;
    case "cancelled_store_admin":
      return defaultLabelColors.red;
    case "cancelled_customer":
      return defaultLabelColors.red;

    //frank shop order status
    case "Placed":
      return defaultLabelColors.color_odr_plcd;
    case "Accepted":
      return defaultLabelColors.color_odr_acp;
    case "Ready":
      return defaultLabelColors.color_odr_pick;
    case "Picked":
      return defaultLabelColors.color_odr_pick;
    case "Deliver":
      return defaultLabelColors.color_odr_delv;
    case "Cancelled":
      return defaultLabelColors.color_odr4_cncl;

    default:
      return "";
  }
};

export const convertMilisecondsToDuration = (
  date_1: any,
  date2: any
): string => {
  try {
    const _duration = moment.duration(moment(date2).diff(date_1));

    let years = _duration.years(); //moment(date2).diff(date_1, "year");
    let months = _duration.months(); //moment(date2).diff(date_1, "month");
    let days = _duration.days(); //moment(date2).diff(date_1, "day");
    let hours = _duration.hours(); //moment(date2).diff(date_1, "hour");
    let minutes = _duration.minutes(); //moment(date2).diff(date_1, "minute");
    let seconds = _duration.seconds(); //moment(date2).diff(date_1, "second");

    let duration = "";

    if (
      years === 0 &&
      months === 0 &&
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds < 40
    ) {
      return "Less than a minute";
    }

    if (years > 1) {
      return "More than 1 year";
    }

    if (years > 0) {
      duration += `${years} ${years > 0 ? "Years" : "Year"} `;
    }
    if (months > 0) {
      duration += `${months} ${months > 0 ? "Months" : "Month"} `;
    }
    if (days > 0) {
      duration += `${days} ${days > 0 ? "Days" : "Day"} `;
    }

    if (hours > 0) {
      duration += `${hours} ${hours > 0 ? "Hours" : "Hour"} `;
    }

    if (minutes > 0) {
      duration += `${minutes} ${minutes > 0 ? "Minutes" : "Minute"} `;
    }

    if (seconds > 0) {
      duration += `${seconds} ${seconds > 0 ? "Seconds" : "Second"} `;
    }

    return duration;
  } catch (error) {
    console.log(error);
    return "N/A";
  }
};

export const getGender = (gender: string): string => {
  switch (gender) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "Male";
  }
};

export const getImageNameFromURL = (urlInput: string): string => {
  const imageFullnameResult = urlInput?.match(/.+(\/|%2F)(.+)/);

  if (!imageFullnameResult) return "";

  const imageFullname = imageFullnameResult[2];

  const imageNameResult = imageFullname?.match(
    /.+(jpg|png|svg|jpeg|webp|bmp|gif)/
  );

  if (!imageNameResult) return imageFullname;

  const imageName = imageNameResult[0];

  if (!imageName) return "";

  return imageName;
};

export const global_error_message =
  "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team.";

export const generateTrimmedID = (id: string, prefix: string): string => {
  const idWithoutPrefix = id.slice(prefix.length);
  const trimmedId = idWithoutPrefix.slice(8);
  return `${prefix}${trimmedId}`;
};

export const restructureArray = (
  inputArray: any,
  parentId: any = null,
  parentName: any = null
): any => {
  const result: any = [];
  inputArray.forEach((item: any) => {
    if (item.parent_module_id === parentId) {
      const newItem: any = {
        module_id: Number(item?.module_id ?? item.id),
        id: `${item?.module_name?.toLowerCase().replace(/\s/g, "-")}`,
        title: item?.module_name ?? "",
        visibility: item.read,
      };

      const children = restructureArray(
        inputArray,
        Number(item?.module_id ?? item.id),
        parentName
          ? `${parentName}/${item?.module_name
              ?.toLowerCase()
              .replace(/ /g, "-")}`
          : `/${item?.module_name?.toLowerCase().replace(/ /g, "-")}`
      );

      if (children.length > 0) {
        newItem.type = "collapse";
        newItem.children = children;
        newItem["_delete"] = item._delete;
        newItem["_download"] = item._download;
        newItem["_print"] = item._print;
        newItem["_read"] = item._read;
        newItem["_update"] = item._update;
        newItem["_write"] = item._write;
        newItem["idx"] = item.idx;
        if (
          newItem["_delete"] &&
          newItem["_download"] &&
          newItem["_print"] &&
          newItem["_read"] &&
          newItem["_update"] &&
          newItem["_write"]
        ) {
          newItem["all"] = true;
        } else {
          newItem["all"] = false;
        }
      } else {
        // If there are no children, treat as an 'item'
        newItem.type = "item";
        newItem.url = parentName
          ? `${parentName}/${item?.module_name
              ?.toLowerCase()
              .replace(/ /g, "-")}`
          : `/${item?.module_name?.toLowerCase().replace(/ /g, "-")}`;
        newItem.breadcrumbs = true;

        newItem["_delete"] = item._delete;
        newItem["_download"] = item._download;
        newItem["_print"] = item._print;
        newItem["_read"] = item._read;
        newItem["_update"] = item._update;
        newItem["_write"] = item._write;
        newItem["idx"] = item.idx;
        if (
          newItem["_delete"] &&
          newItem["_download"] &&
          newItem["_print"] &&
          newItem["_read"] &&
          newItem["_update"] &&
          newItem["_write"]
        ) {
          newItem["all"] = true;
        } else {
          newItem["all"] = false;
        }
      }
      result.push(newItem);
    }
  });
  return result;
};

export const capitalizeFirstWord=(str: string): string=> {
  if (!str) return str; // If the string is empty or null, return it as is
  return str.charAt(0).toUpperCase() + str.slice(1);
}
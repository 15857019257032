import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import apiServices from "services/RequestHandler";
import { RootState } from "store";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
import {
  clearSelectedShop,
  singleMiniShop,
  togglePanels,
} from "store/vendorReducers";
import ActivityLogs from "ui-components/ActivityLog";
import Button from "ui-components/Button";
import Comments from "ui-components/Comments";
import Datatable from "ui-components/Datatable";
import DraggableDialog from "ui-components/DraggableDialog";
import LoadingComponent from "ui-components/LoadinComponent";
import Toast from "ui-components/Toast";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
} from "utils/helper";
import Profile from "views/Vendors/MiniShop/MiniShopByID/Profile";
import ProfileInfo from "views/Vendors/MiniShop/MiniShopByID/ProfileInfo";
import Status from "views/Vendors/MiniShop/MiniShopByID/Status";
import Other from "./Other";
import ServiceMethod from "./ServiceMethod";
import ShopTiming from "./ShopTiming";
import { C2COrderByShop } from "store/orderReducers";
import { allProduct } from "store/productReducer";
import ProductDetail from "./ProductDetail";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ height: 370, marginTop: value === 0 ? 30 : 5 }}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function MiniShopByID(props: any) {
  const { classes } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const vendorSlice = useSelector((state: RootState) => state.vendors);
  const OrderByShop = useSelector((state: RootState) => state.orders);
  // const productSlice = useSelector((state: RootState) => state?.shopProduct);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      e_merchant: null,
      manage_miniShop: false,
      product_detail: false,
      selected_tab: 0,
      is_changed: false,
    }
  );
  let { id } = useParams();
  // console.log(state?.mini_shop,"miniShop")
  React.useEffect(() => {
    getMiniShop();
    getShopOrderById();
    // getShopProduct();

    return () => {
      dispatch(setSubTitle(null as any));
      dispatch(togglePanels(true as any));
      setState({
        products: [],
        orders: [],
      });
      dispatch(clearSelectedShop(null as any));
    };
  }, []);

  React.useEffect(() => {
    setProductnOrder();
  }, [JSON.stringify(vendorSlice?.single_mini_shop_data)]);

  const getMiniShop = async () => {
    try {
      dispatch(
        singleMiniShop({
          end_point: `/c2c-shop-configure/get-by-id/${id}`,
        } as any)
      );
      dispatch(setTitle("Frank shop" as any));
    } catch (error) {
      console.log(error);
    }
  };
  const getShopOrderById = async () => {
    try {
      dispatch(
        C2COrderByShop({
          end_point: `/c2c-orders/admin/by-shop`,
          shop_id: Number(id),
          page: 1,
          limit: 50,
        })
      );
    } catch (error) {}
  };
  // const getShopProduct = async () => {
  //   try {
  //     dispatch(
  //       allProduct({
  //         end_point: `c2c-products/all`,
  //         shop_id: Number(id),
  //         page: 1,
  //         limit: 50,
  //       })
  //     );
  //   } catch (error) {}
  // };

  const setProductnOrder = (): void => {
    try {
      dispatch(
        setSubTitle(
          `${
            vendorSlice?.single_mini_shop_data?.shop_name ??
            `${vendorSlice?.single_mini_shop_data?.first_name}'Shop`
          }` as any
        )
      );
      //product

      let _products = [];
      const total_product =
        vendorSlice?.single_mini_shop_data?.products?.length;
      if (total_product) {
        for (let index = 0; index < total_product; index++) {
          const item = vendorSlice?.single_mini_shop_data?.products[index];
          _products.push([
            item?.id || "- - -",
            item?.product_title || "- - -",
            `${numberFormatWithoutCurrency(item?.price ?? 0)} €` || "0",
            item?.brand?? "- - -",

            {
              ui: (
                <div>
                  <img
                    src={item?.images[0]}
                    alt="frank.jpeg"
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                  ></img>
                </div>
              ),
            },
            {
              ui: (
                <div
                  className="Roboto-Medium"
                  // className={classNames(
                  //   classes.card_value_style,
                  //
                  // )}
                  style={{
                    flex: 1,
                    display: "-webkit-box",
                    maxWidth: 200,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                  title={item?.product_desc ?? "--"}
                >
                  {item?.product_desc ?? "- - -"}
                </div>
              ),
            },
            item?.color,
            item?.prod_size,
          ]);
        }
      }
      //
      let _data = vendorSlice?.single_mini_shop_data;

      setState({
        mini_shop: {
          ..._data,
          selected_is_active: {
            label: _data?.is_active ? "Active" : "Inactive",
            value: _data?.is_active ?? false,
          },
          selected_is_verified: {
            label: _data?.is_verified ? "Verified" : "Not Verified",
            value: _data?.is_verified ?? false,
          },
        },
        products: _products,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(state?.products)
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setState({
      selected_tab: newValue,
    });
  };

  const handleSelect = (name: any, event: any, values: any): void => {
    try {
      setState({
        mini_shop: {
          ...state.mini_shop,
          [name]: values?.value ?? null,
          [`selected_${[name]}`]: values ? values : null,
        },
        is_changed: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateStatus = async (): Promise<void> => {
    try {
      let final_obj = {
        id: Number(state.mini_shop?.id),
        is_active: state.mini_shop?.selected_is_active?.value ?? false,
        is_verified: state.mini_shop?.selected_is_verified?.value ?? false,
      };
      // console.log(final_obj);
      const resp = await apiServices.postFromApi(
        "c2c-shop-configure/activate",
        final_obj,
        ""
      );
      if (resp.status === 201) {
        Toast.showSuccess(`Status updated successfully`);
        setState({
          manage_miniShop: false,
        });
        getMiniShop();
      } else {
        Toast.showError(
          // "Unable perform action, please try again, if errors again then contact to our Support Team"
          resp.error.message
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRow = (event: any, row: any, index: any) => {
    try {
      if (row[0]) {
        const selected_customer = Number(row[0].replace("ODR-", ""));
        navigate(`/oms/orders/${selected_customer}`);

        setState({
          selected_customer,
        });
      } else {
        setState({
          selected_customer: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickRowProduct = (event: any, row: any, index: any) => {
    try {
      if (row[0]) {
        const p_id = row[0];
        setState({
          p_id,
          product_detail: true,
        });
      } else {
        setState({
          p_id: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getShopOrderByFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    searching: any
  ): Promise<void> => {
    try {
      let query = `/c2c-orders/admin/by-shop`;
      // end_point: `/c2c-orders/admin/by-shop`,

      if (start_date && end_date) {
        // query += `&start=${start_date}&end=${end_date}`;
      }
      dispatch(
        C2COrderByShop({
          end_point: query,
          shop_id: Number(id),
          page: page_number,
          limit: limit,
          start_date: start_date,
          end_date: end_date,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.main_layout_padding}>
      <LoadingComponent activity={vendorSlice.activity} />
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <Button
            value={"Manage"}
            classesNames={classNames(
              classes.button_width,
              classes.contained_button
            )}
            onClick={() => setState({ manage_miniShop: true })}
          />
          &emsp;
          <Button
            value={"Back to All"}
            classesNames={classNames(
              classes.button_width,
              classes.outlined_button
            )}
            onClick={() => navigate(-1)}
          />
        </Grid>

        <Grid xs={12} md={6} item>
          <Profile
            {...props}
            {...state}
            panel_head={"Shop Detail"}
            // from_vendors={true}
            details={state.mini_shop}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Status
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Status & Summary"}
            // from_vendors={true}
            details={vendorSlice?.single_mini_shop_data}
          />
          <Other
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Shop Address"}
            // from_vendors={true}
            details={vendorSlice?.single_mini_shop_data}
          />
        </Grid>

        <Grid xs={12} md={6} item>
          {" "}
          <ServiceMethod
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Service Method"}
            // from_vendors={true}
            details={vendorSlice?.single_mini_shop_data}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <ShopTiming
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Business Hours"}
            // from_vendors={true}
            details={vendorSlice?.single_mini_shop_data}
          />
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Products"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: " ID", alignment: "left" },
                  { name: "Title", alignment: "left" },
                  { name: "Price", alignment: "left" },
                  { name: "Brand", alignment: "left" },
                  // { name: "Category", alignment: "left" },

                  { name: "Image", alignment: "left" },
                  { name: "Description", alignment: "left" },
                  { name: "Color", alignment: "left" },
                  { name: "Size", alignment: "left" },
                ]}
                // rows={productSlice?.all_product || []}

                rows={state?.products || []}
                // onRowClick={handleClickRowProduct}
                min_width={"100%"}
                ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={false}
                show_header={false}
                revert_header_color={true}
                // filterByType={false}
                // handleUpdateRecords={getShopOrderByFilter}
                // enablePagination
                // previous_count={Number(
                //   productSlice?.all_product_paginate_data?.previous_count ?? 0
                // )}
                // current_count={Number(
                //   productSlice?.all_product_paginate_data?.current_count ?? 0
                // )}
                // total_records={Number(
                //   productSlice?.all_product_paginate_data?.total_count ?? 0
                // )}
                // count={Number(
                //   productSlice?.all_product_paginate_data?.total_pages ?? 1
                // )}
                // show_button
                // // btn_classes2={classes}
                // btn_label={"Add Product "}
                // btn_disable={true}
                // onButtonClick={() => setState({ product_detail: true })}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={12} item>
          <Accordion
            defaultExpanded
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Orders"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: "Order No", alignment: "left" },
                  { name: "Order Date.", alignment: "left" },
                  { name: "Customer Name", alignment: "left" },
                  { name: "Total Product Price", alignment: "left" },
                  { name: "Shop Name", alignment: "left" },
                  { name: "Order Status ", alignment: "left" },

                  { name: "Service Method ", alignment: "left" },
                  { name: "Platform ", alignment: "left" },
                  { name: "Payment  Method", alignment: "left" },
                  { name: "Payment Status", alignment: "left" },
                  { name: "Expected Delivery Date", alignment: "left" },
                  { name: "Rider Name", alignment: "left" },
                  { name: "Products", alignment: "left" },
                  { name: "Completed At / Cancelled At", alignment: "left" },
                ]}
                rows={OrderByShop?.c2c_orders_by_shop || []}
                onRowClick={handleClickRow}
                min_width={"100%"}
                ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={true}
                show_header={true}
                revert_header_color={true}
                filterByType={false}
                handleUpdateRecords={getShopOrderByFilter}
                enablePagination
                previous_count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data
                    ?.previous_count ?? 0
                )}
                current_count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data
                    ?.current_count ?? 0
                )}
                total_records={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data?.total_count ??
                    0
                )}
                count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data?.total_pages ??
                    1
                )}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Comment"}
              </span>
            </AccordionSummary>

            <AccordionDetails
            // className={classes.accordion_detail_padding}
            >
              {id && <Comments id={id} margin={0} classes={classes} />}
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Activity log */}
        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Activity Logs"}
              </span>
            </AccordionSummary>

            <AccordionDetails
            // className={classes.accordion_detail_padding}
            >
              <ActivityLogs id={id} margin={0} classes={classes} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <DraggableDialog
        open={state.manage_miniShop}
        handleClose={() => setState({ manage_miniShop: false })}
        dialog_title={"Manage Frank shop"}
        classes={classes}
        maxWidth={"lg"}
        dialogBody={
          <DialogContent dividers>
            <Tabs
              value={state.selected_tab}
              onChange={handleChangeTab}
              textColor="inherit"
              style={{ borderBottom: "2px solid lightgray" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#ee6931",
                },
              }}
              sx={{
                "& .MuiTabs-scroller": {
                  "&. MuiTabs-flexContainer": {
                    zIndex: 2,
                    position: "relative",
                  },
                },
              }}
            >
              <Tab
                className={classNames(
                  state.selected_tab === 0
                    ? classes?.active_tab
                    : classes?.inactive_tab
                )}
                label={`Frank shop Profile`}
                {...a11yProps(0)}
              />
              {/* <Tab
                className={classNames(
                  state.selected_tab === 1
                    ? classes?.active_tab
                    : classes?.inactive_tab
                )}
                label="Space Documents"
                {...a11yProps(1)}
              /> */}
            </Tabs>
            <SwipeableViews
              index={state.selected_tab}
              onChangeIndex={(val) => handleChangeTab(null as any, val)}
              style={{ marginTop: 15 }}
            >
              <TabPanel value={state.selected_tab} index={0}>
                <ProfileInfo
                  {...state}
                  {...props}
                  // e_merchant={state.from_vendors}
                  handleSelect={handleSelect}
                  details={state.mini_shop}
                />
              </TabPanel>
            </SwipeableViews>
          </DialogContent>
        }
        dialogFooter={
          <DialogActions style={{ padding: "20px" }}>
            <div style={{ flex: 1 }}>
              {state.selected_tab === 0 ? (
                <Button
                  classesNames={classNames(
                    classes.button_width,
                    classes.outlined_button
                  )}
                  disable={state.is_changed ? false : true}
                  onClick={handleUpdateStatus}
                  style={{ marginRight: 20 }}
                  value={"Update"}
                />
              ) : (
                <span className={classes.radio_label} style={{ color: "gray" }}>
                  Space Shop - Essence Discovers
                </span>
              )}
            </div>
            {state.selected_tab === 0 && (
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                style={{ marginRight: 20 }}
                value={"Copy as Text"}
              />
            )}
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              value="Print"
              style={{ marginRight: 20 }}
            />
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.contained_button
              )}
              value="Close"
              onClick={() => {
                setState({ manage_miniShop: false });
                let _data = vendorSlice?.single_mini_shop_data;
                setState({
                  mini_shop: {
                    ..._data,
                    selected_is_active: {
                      label: _data?.is_active ? "Active" : "Inactive",
                      value: _data?.is_active ?? false,
                    },
                    selected_is_verified: {
                      label: _data?.is_verified ? "Verified" : "Not Verified",
                      value: _data?.is_verified ?? false,
                    },
                  },
                });
              }}
            />
          </DialogActions>
        }
      />
      <DraggableDialog
        open={state.product_detail}
        handleClose={() => setState({ product_detail: false, p_id: null })}
        dialog_title={"Product Detail"}
        classes={classes}
        maxWidth={"lg"}
        dialogBody={
          <ProductDetail
            classes={classes}
            id={state?.p_id}
            shop_detail={vendorSlice?.single_mini_shop_data}
            handleClose={() => setState({ product_detail: false, p_id: null })}
          />
        }
      />
    </div>
  );
}

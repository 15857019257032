import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { vendors_data } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";

export interface VendorStates {
  single_merchant: any;
  all_emerchants: any;
  all_emerchants_data: any;
  e_merchants_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_d2c: any;
  all_d2c_stores: any;
  all_d2c_stores_data: any;

  single_spaces: any;
  all_spaces: any;
  all_spaces_data: any;
  space_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_mini_shop: any;
  single_mini_shop_data: any;

  all_mini_shop: any;
  all_mini_shop_data: any;
  mini_shop_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  panels: {
    profile: any;
    status: any;
    orders: any;
  };

  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: VendorStates = {
  single_merchant: {},
  all_emerchants: [],
  all_emerchants_data: [],

  single_d2c: {},
  all_d2c_stores: [],
  all_d2c_stores_data: [],

  single_spaces: {},
  all_spaces: [],
  all_spaces_data: [],

  single_mini_shop: {},
  all_mini_shop: [],
  all_mini_shop_data: [],
  single_mini_shop_data: [],

  panels: {
    profile: true,
    status: true,
    orders: true,
  },
  activity: true,
};

export const allEmerchants = createAsyncThunk(
  "admin/stores",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      // response = vendors_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allSpaces = createAsyncThunk(
  "vendors/spaces",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allD2C = createAsyncThunk(
  "vendors/d2",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      response = vendors_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const AllMiniShop = createAsyncThunk(
  "c2c-shop-configure",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data?.end_point,
        {
          page: data?.page,
          limit: data?.limit,
          start_date:data?.start_date,
          end_date:data?.end_date
        },
        "configure"
      );
      // response = vendors_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const singleMiniShop = createAsyncThunk(
  "/c2c-shop-configure/get-by-id/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "single-shop"
      );
      // response = vendors_data.data[0];
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleMerchant = createAsyncThunk(
  "/admin/stores/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      // response = vendors_data.data[0];
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleSpace = createAsyncThunk(
  "transporter/fleetmanger/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "space");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleD2C = createAsyncThunk(
  "transporter/d2c-business/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        "countries/web" || data.end_point,
        "orders"
      );
      response = vendors_data.data[0];
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const vendorSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    clearEmerchants: (state: VendorStates, action: any) => {
      state.all_emerchants = [];
      state.all_emerchants_data = [];
    },
    toggleProfile: (state: VendorStates, action: any) => {
      state.panels.profile = action.payload;
    },
    toggleStatus: (state: VendorStates, action: any) => {
      state.panels.status = action.payload;
    },
    handleChangeOrdersPanel: (state: VendorStates, action: any) => {
      state.panels.orders = action.payload;
    },
    togglePanels: (state: VendorStates, action: any) => {
      state.panels.profile = action.payload;
      state.panels.status = action.payload;
      state.panels.orders = action.payload;
    },
    clearSelectedShop: (state: VendorStates, action: any) => {
      state.single_mini_shop_data = null;
      state.single_mini_shop = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allEmerchants.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allEmerchants.fulfilled, (state, { payload }) => {
        let airRiders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          airRiders.push([
            item?.id || "- - -",
            item?.store_title || "- - -",
            item?.platform || "- - -",
            item?.locations || "- - -",
            `${item?.phone}`,
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#3026f1" : "gray",
            },
            {
              value: item?.is_verified,
              name: item?.is_verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.is_verified ? "#3026f1" : "#f10000",
            },
            item?.verified_date
              ? moment(item?.verified_date).format("DD-MMM-YYYY")
              : "- - -",
            item?.store_owner || "- - -",
          ]);
        }
        state.all_emerchants = airRiders;
        state.all_emerchants_data = payload?.data;

        state.e_merchants_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(singleMerchant.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleMerchant.fulfilled, (state, { payload }) => {
        state.single_merchant = {
          ...payload.result,
          selected_is_active: {
            label: payload?.result?.is_active ? "Active" : "Inactive",
            value: payload?.result?.is_active,
          },
          selected_is_verified: {
            label: payload?.result?.is_verified ? "Verified" : "Not Verified",
            value: payload?.result?.is_verified,
          },
        };
        state.activity = false;
      })
      .addCase(allSpaces.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allSpaces.fulfilled, (state, { payload }) => {
        let AllSpaces = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          AllSpaces.push([
            item?.id || "- - -",
            item?.space_title || "- - -",
            item?.locations || "- - -",
            `${item?.phone}`,
            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active ? "#3026f1" : "gray",
            },
            {
              value: item?.is_verified,
              name: item?.is_verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.is_verified ? "#3026f1" : "#f10000",
            },
            item?.verified_date
              ? moment(item?.verified_date).format("DD-MMM-YYYY")
              : "- - -",
            item?.space_owner || "- - -",
          ]);
        }
        state.all_spaces = AllSpaces;
        state.all_spaces_data = payload?.data;

        state.space_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(allD2C.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allD2C.fulfilled, (state, { payload }) => {
        let allD2Cs = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          allD2Cs.push([
            item?.id || "- - -",
            item?.store_name || "- - -",
            item?.city_country || "- - -",
            `${item?.phone}`,
            {
              value: item?.status,
              name: item?.status ? "Active" : "Inactive",
              is_bool: true,
              color: item?.status ? "#3026f1" : "gray",
            },
            {
              value: item?.verified,
              name: item?.verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.verified ? "#3026f1" : "#f10000",
            },
            item?.date_verified || "- - -",
            item?.owner || "- - -",
          ]);
        }
        state.all_d2c_stores = allD2Cs;
        state.all_d2c_stores_data = payload?.data;
        state.activity = false;
      })
      .addCase(singleD2C.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleD2C.fulfilled, (state, { payload }) => {
        state.single_d2c = payload;
        state.activity = false;
      })
      .addCase(singleSpace.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleSpace.fulfilled, (state, { payload }) => {
        state.single_spaces = {
          ...payload.result,
          selected_is_active: {
            label: payload?.result?.is_active ? "Active" : "Inactive",
            value: payload?.result?.is_active,
          },
          selected_is_verified: {
            label: payload?.result?.is_verified ? "Verified" : "Not Verified",
            value: payload?.result?.is_verified,
          },
        };
        state.activity = false;
      })

      .addCase(AllMiniShop.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(AllMiniShop.fulfilled, (state: VendorStates, { payload }) => {
        let MiniShop = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          MiniShop.push([
            // item?.id || "- - -",
            { name: item?.id, hidden: true },
            item?.shop_name ?? `${item?.first_name}'s shop`,
            {// eslint-disable-next-line no-use-before-define
              ui: (
                <div
                  className="Roboto-Medium"
                  // className={classNames(
                  //   classes.card_value_style,
                  //
                  // )}
                  style={{
                    flex: 1,
                    display: "-webkit-box",
                    maxWidth: 200,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item?.shop_desc ?? "- - -"}
                </div>
              ),
            },
            item?.type_name || "N/A",
            `${item?.first_name ?? "- -"} ${item?.last_name ?? "- -"}` ??
              "- - -",

            {
              value: item?.is_active,
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_verified
                ? defaultLabelColors?.green
                : defaultLabelColors?.red,
              style: {
                color: item?.is_verified
                  ? defaultLabelColors?.green
                  : defaultLabelColors?.red,
              },
            },
            {
              value: item?.is_verified,
              name: item?.is_verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.is_verified
                ? defaultLabelColors?.green
                : defaultLabelColors?.red,
              style: {
                color: item?.is_verified
                  ? defaultLabelColors?.green
                  : defaultLabelColors?.red,
              },
            },

            item?.created_at
              ? moment(item?.created_at).format("DD/MM/YYYY HH:mm")
              : "- -",
            item?.updated_at
              ? moment(item?.updated_at).format("DD/MM/YYYY HH:mm")
              : "- -",
            item?.email || "-",

            `${item?.phone_code ?? "- -"} ${item?.phone ?? "- -"}` ?? "- - -",
          ]);
        }
        
        state.all_mini_shop = MiniShop;
        state.all_mini_shop_data = payload?.data;

        state.mini_shop_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(singleMiniShop.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleMiniShop.fulfilled, (state, { payload }) => {
        // let Single_MiniShop = [] as any;
        // for (let index = 0; index < payload?.data.length; index++) {
        //   const item = payload?.data[index];
        //   Single_MiniShop.push([
        //     item?.id || "- - -",
        //     item?.shop_name || "- - -",
        //     item?.shop_desc || "- - -",
        //     item?.shop_type || "- - -",
        //     `${item?.phone}`,
        //     {
        //       value: item?.is_active,
        //       name: item?.is_active ? "Active" : "Inactive",
        //       is_bool: true,
        //       color: item?.is_active ? "#3026f1" : "gray",
        //     },
        //     {
        //       value: item?.is_verified,
        //       name: item?.is_verified ? "Verified" : "Not Verified",
        //       is_bool: true,
        //       color: item?.is_verified ? "#3026f1" : "#f10000",
        //     },

        //     item?.url || "- - -",
        //   ]);
        //   console.log("checking",index)
        // }
        // state.single_mini_shop = Single_MiniShop;
        state.single_mini_shop_data = payload?.data;
        state.activity = false;
      });

    //
  },
});

export const {
  clearEmerchants,
  handleChangeOrdersPanel,
  togglePanels,
  toggleProfile,
  toggleStatus,
  clearSelectedShop,
} = vendorSlice.actions;

export default vendorSlice.reducer;
